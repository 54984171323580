
import Vue from "vue"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import { mapGetters, mapActions } from "vuex"
import { getAttributes } from "~/utils/block"
import { SET_PICKUP_ADDRESS, SET_LISTING } from "~/store/listing/actions"
import { GET_PICKUP_ADDRESS } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { placeToAddress } from "~/scripts/convert"

export default Vue.extend({
  name: "NewLocationPickupInput",

  components: {
    ValidationProvider
  },

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    virgin: {
      type: Boolean
    },
    lazy: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Boolean
    },
    deliveryInput: {
      type: Object,
      default: undefined
    },
    inputClasses: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters("listing", {
      getPickupAddress: GET_PICKUP_ADDRESS
    }),

    attributes(): Record<string, any> | null {
      return this.virgin ? null : this.getAttributes(this.block)
    }
  },

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  },

  methods: {
    ...mapActions("listing", {
      setPickupAddress: SET_PICKUP_ADDRESS,
      setListing: SET_LISTING
    }),

    getAttributes,

    setPickupPlace(place: google.maps.places.PlaceResult | null): void {
      // Set the pickup address in Vuex
      this.setPickupAddress(place !== null ? placeToAddress(place) : null)

      // Trigger Snowplow events
      if (place && place.formatted_address) {
        // Fire the "selected" event
        this.$snowplow.trackEvent({
          category: "moving_from",
          action: "selected",
          property: place.formatted_address
        })
      }

      // Focus on the delivery input if it's empty
      if (place !== null && this.deliveryInput) {
        const deliveryInputElement =
          // @ts-ignore-next-line
          this.deliveryInput.$el.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
        if (deliveryInputElement && deliveryInputElement.value === "") {
          deliveryInputElement.focus()
        }
      }
    },

    // Handle focus event
    onFocus(): void {
      this.$snowplow.trackEvent({
        category: "moving_from",
        action: "click"
      })
    },

    // Handle blur event
    onBlur(): void {
      // Access the input element using the ref
      const inputElement = (this.$refs.pickupInput as Vue)?.$el.querySelector(
        "input"
      ) as HTMLInputElement

      if (!inputElement) {
        return
      }

      const inputValue = inputElement.value

      if (inputValue) {
        this.$snowplow.trackEvent({
          category: "moving_from",
          action: "entered",
          property: inputValue
        })
      }
    }
  }
})
