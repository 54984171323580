
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import { ValidationObserver } from "vee-validate"
import axios from "axios"
import { ADD_FLOORS } from "~/store/property/actions"
import { patternCheck } from "~/utils/block"
import {
  GET_LISTING,
  GET_ITEMS_COUNT,
  GET_PICKUP_FLOOR,
  GET_PICKUP_LIFT_AVAILABLE,
  GET_DELIVERY_FLOOR,
  GET_DELIVERY_LIFT_AVAILABLE,
  GET_PICKUP_ADDRESS,
  GET_DELIVERY_ADDRESS
} from "~/store/listing/getters"
import {
  ListingService,
  getCategoryById,
  getV4Url,
  getV4UrlByCategoryId,
  getV4ListingPayload,
  addPreListingIdToSessionStorage,
  isValidLocation
} from "~/services/public/Listing"
import {
  AVAILABILITY_REMARK,
  CALL_US,
  ITEM_IDENTIFIER,
  RETRIEVE_QUOTE,
  VALIDATION
} from "~/constants/blocks"
import { redirectToV2 } from "~/utils/v4-redirect"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import eventBus from "~/scripts/bus"
import {
  SET_DELIVERY_FLOOR,
  SET_DELIVERY_LIFT_AVAILABLE,
  SET_PICKUP_FLOOR,
  SET_PICKUP_LIFT_AVAILABLE
} from "~/store/listing/actions"
import { SET_INVENTORY, SET_WINDOW_WIDTH } from "~/store/pages/actions"
import { extractHref, getInnerText, getv4LocaleFromI18nLocale } from "~/scripts/useful-functions"
import { getFurnitureDefaultInventory } from "~/services/public/ItemService"
import {
  GET_INVENTORY_DESKTOP,
  GET_IS_SMALL_OR_MEDIUM,
  GET_WINDOW_WIDTH
} from "~/store/pages/getters"
import { GET_FLOORS } from "~/store/property/getters"
import { FLOOR_GROUND, FLOORS, LIFT_AVAILABLE, LIFT_UNAVAILABLE } from "~/constants/listing"
import { CATEGORY_V4_ID_FURNITURE } from "~/constants/category"

interface ComponentData {
  submittedForm: boolean
  submittingForm: boolean
  emailFormShow: boolean
  trustpilotCustomers: string
  showFurnitureItems: boolean | null
  initInventory: boolean
  itemCategories: any
  hasExtensionState: boolean
  floors: any[]
}

export default Vue.extend({
  name: "LocationForm",
  components: { ValidationObserver },

  props: {
    categoryV4Id: { type: Number, default: CATEGORY_V4_ID_FURNITURE },
    hasExtension: { type: Boolean },
    block: { type: Object as () => Block, required: true }
  },

  data(): ComponentData {
    return {
      submittedForm: false,
      submittingForm: false,
      emailFormShow: false,
      trustpilotCustomers: "0",
      showFurnitureItems: false,
      initInventory: false,
      itemCategories: null,
      hasExtensionState: this.hasExtension,
      floors: FLOORS
    }
  },

  computed: {
    ...mapGetters("pages", {
      getWindowWidth: GET_WINDOW_WIDTH,
      isSmallOrMedium: GET_IS_SMALL_OR_MEDIUM,
      getInventoryDesktop: GET_INVENTORY_DESKTOP
    }),
    ...mapGetters("listing", {
      getListing: GET_LISTING,
      getItemsCount: GET_ITEMS_COUNT,
      getPickupFloor: GET_PICKUP_FLOOR,
      getDeliveryFloor: GET_DELIVERY_FLOOR,
      getPickupLiftAvailable: GET_PICKUP_LIFT_AVAILABLE,
      getDeliveryLiftAvailable: GET_DELIVERY_LIFT_AVAILABLE,
      getPickupAddress: GET_PICKUP_ADDRESS,
      getDeliveryAddress: GET_DELIVERY_ADDRESS
    }),
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    }),
    ...mapGetters("property", {
      getStoreFloors: GET_FLOORS
    }),

    isLargeOrAbove(): any {
      return !this.isSmallOrMedium
    },

    locationBlock(): any {
      return this.categoryV4Id ? this.block.innerBlocks[0] : this.block.innerBlocks[1]
    },

    ctaBlock(): any {
      return this.block.innerBlocks[this.block.innerBlocks.length - 1]
    },

    getInventoryComputed(): boolean {
      return this.isSmallOrMedium || this.hasExtensionState
    },

    hasAvailabilityRemark(): boolean {
      return patternCheck(this.block.attributes, AVAILABILITY_REMARK)
    },

    hasItemIdentifier(): boolean {
      return patternCheck(this.block.attributes, ITEM_IDENTIFIER)
    },

    hasCallUs(): boolean {
      return patternCheck(this.block.attributes, CALL_US)
    },

    hasRetrieveQuote(): boolean {
      return patternCheck(this.block.attributes, RETRIEVE_QUOTE)
    },

    isValidForm(): boolean {
      const listing = this.getListing
      if (this.hasValidation(this.locationBlock)) {
        if (!isValidLocation(listing?.pickup) || !isValidLocation(listing?.delivery)) {
          return false
        }
      }

      return true
    },

    hasItemsDesktop(): boolean {
      return this.isLargeOrAbove && this.hasExtensionState && this.getItemsCount
    },

    getFloors(): any[] {
      return this.getStoreFloors || this.floors
    },
    pickupFloor: {
      get(): string | null {
        return this.getPickupFloor
      },
      set(value: string | null): void {
        if (value === FLOOR_GROUND) {
          this.setPickupLiftAvailable(null)
        }
        this.setPickupFloor(value)
      }
    },
    deliveryFloor: {
      get(): string | null {
        return this.getDeliveryFloor
      },
      set(value: string | null): void {
        if (value === FLOOR_GROUND) {
          this.setDeliveryLiftAvailable(null)
        }
        this.setDeliveryFloor(value)
      }
    },
    pickupLiftAvailable: {
      get(): boolean {
        if (this.getPickupLiftAvailable === null) {
          this.setPickupLiftAvailable(LIFT_UNAVAILABLE)
          return true
        }
        return this.getPickupLiftAvailable === 1
      },
      set(value: boolean): void {
        this.setPickupLiftAvailable(value ? LIFT_AVAILABLE : LIFT_UNAVAILABLE)
      }
    },

    deliveryLiftAvailable: {
      get(): boolean {
        if (this.getDeliveryLiftAvailable === null) {
          this.setDeliveryLiftAvailable(LIFT_UNAVAILABLE)
          return true
        }
        return this.getDeliveryLiftAvailable
      },
      set(value: boolean): void {
        this.setDeliveryLiftAvailable(value ? LIFT_AVAILABLE : LIFT_UNAVAILABLE)
      }
    },

    getLiftAvailableOptions(): any[] {
      return [
        {
          name: this.$t("location_form.lift_available"),
          value: LIFT_AVAILABLE
        },
        {
          name: this.$t("location_form.lift_not_available"),
          value: LIFT_UNAVAILABLE
        }
      ]
    },

    hasSetPickupAddress(): boolean {
      return (
        this.getPickupAddress.coordinates.lat !== null &&
        this.getPickupAddress.coordinates.lng !== null
      )
    },

    hasSetDeliveryAddress(): boolean {
      return (
        this.getDeliveryAddress.coordinates.lat !== null &&
        this.getDeliveryAddress.coordinates.lng !== null
      )
    }
  },

  watch: {
    onSubmittingFormChanges(val) {
      if (val) {
        eventBus.$emit("submitting-location-form")
      } else {
        eventBus.$emit("submitted-location-form")
      }
    }
  },

  async created() {
    this.$store.dispatch("pages/" + SET_INVENTORY, null)
    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey

    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotCustomers = response.data.numberOfReviews.total
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }

    const itemsUrl = this.$config.v4Host + "/api/v4/booking-flow/config/house-move"
    try {
      const axiosInstance = axios.create() as any
      const headers = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "AV-locale": getv4LocaleFromI18nLocale(this.$i18n.locale)
        }
      }
      const response = await axiosInstance.get(itemsUrl, headers)
      if (response.status === 200 && response.data) {
        this.addFloors(response.data.propertyFloors.floors)
      }
    } catch (err) {
      console.log("Property config error", err)
    }
  },

  async mounted() {
    if (!this.initInventory) {
      this.initInventory = true
      // Safety mechanism to disable IoLP Desktop when LocationFormExtension is missing
      if (this.hasExtensionState && !this.getInventoryDesktop) {
        this.hasExtensionState = false
      }
      if (this.getInventoryComputed) {
        this.itemCategories = (await getFurnitureDefaultInventory()).items
      } else {
        // @ts-ignore-next-line
        window.dataLayer?.push({ event: "show_responseiq" })
      }

      const inventoryMobile = this.getInventoryComputed && this.isSmallOrMedium
      await this.$store.dispatch("pages/" + SET_INVENTORY, inventoryMobile)

      // const inventoryDesktop = this.getInventoryComputed && !this.getIsSmallOrMedium
      // if (inventoryDesktop) {
      //   // Enable Sentry Replay tagged with Snowplow Domain UserID
      //   const { getReplay, setTag } = await import("@sentry/vue")
      //   getReplay()?.start()
      //   setTag("sp.domain_userid", this.$snowplow.getSnowplowDomainIds().domain_userid)
      // }

      const ua = navigator.userAgent.toLowerCase()
      // The implemented workaround only works on Safari & Chrome with iOS 16 & iOS 17
      if (
        ua.includes("safari") &&
        navigator.maxTouchPoints > 2 &&
        (ua.includes("version/16") ||
          ua.includes("version/17") ||
          ua.includes("iphone os 16_") ||
          ua.includes("iphone os 17_"))
      ) {
        if (ua.includes("chrome")) {
          document.body.classList.add("chrome")
        } else {
          document.body.classList.add("safari")
        }
      }
    }
  },

  beforeMount() {
    eventBus.$on("submit-location-form", this.submitForm)
    if (!this.initInventory) {
      window.addEventListener("resize", () => {
        this.setMobileOrDesktop()
      })
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setMobileOrDesktop)
    eventBus.$off("submit-location-form")
  },

  methods: {
    ...mapActions("listing", {
      setPickupFloor: SET_PICKUP_FLOOR,
      setDeliveryFloor: SET_DELIVERY_FLOOR,
      setPickupLiftAvailable: SET_PICKUP_LIFT_AVAILABLE,
      setDeliveryLiftAvailable: SET_DELIVERY_LIFT_AVAILABLE
    }),
    ...mapActions("property", {
      addFloors: ADD_FLOORS
    }),

    extractHref,
    getInnerText,

    setMobileOrDesktop() {
      this.$store.dispatch("pages/" + SET_WINDOW_WIDTH, window.innerWidth)
    },

    async tryToOpenFurnitureItemsModal() {
      if (!this.itemCategories) {
        this.itemCategories = (await getFurnitureDefaultInventory()).items
      }
      if (this.isValidForm) {
        this.showFurnitureItems = true
      } else {
        this.$snowplow.trackIOLPButtonDisabled(this.getItemsCount, "Form")
        await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate()
      }
    },

    scrollInputToTop(el) {
      if (this.getWindowWidth && this.getWindowWidth > 768) return
      // @ts-ignore
      let element
      switch (el) {
        case "pickup":
          element = this.$refs.pickupInput
          break
        case "delivery":
          element = this.$refs.deliveryInput
          break
        default:
          return
      }
      if (element && element.$el.offsetTop) {
        element.$el.querySelector("input").focus()
        setTimeout(() => {
          window.scrollTo({
            top: element.$el.offsetTop - 25,
            behavior: "smooth"
          })
        }, 300)
      }
    },

    async submitForm(isCalledFromPage = true) {
      if (this.submittingForm) return
      this.submittedForm = true
      this.submittingForm = true
      try {
        const listing = this.getListing

        const isFirstStepValid = await (
          this.$refs.observer as InstanceType<typeof ValidationObserver>
        ).validate()

        let isValid = isFirstStepValid

        const isSecondStepValid =
          this.$i18n.locale === "es" || !this.getInventoryComputed || !!this.getItemsCount
        if (isValid) {
          isValid = isSecondStepValid
        }

        if (!isValid && this.$i18n.locale === "en") {
          if (this.getInventoryComputed && isCalledFromPage) {
            const errors = (isFirstStepValid ? "" : "Form") + (isSecondStepValid ? "" : "Items")
            this.$snowplow.trackCTAButtonDisabled("page", this.getItemsCount, errors)
          }
          this.submittingForm = false
          eventBus.$emit("close-dropdown")
          this.hideItems()
          return
        }

        if (!isValid && this.$i18n.locale === "es") {
          this.submittingForm = false
          return
        }

        if (this.getInventoryComputed && isCalledFromPage) {
          this.$snowplow.trackCTAButton("page", this.getItemsCount)
        }

        let categoryV4Id = CATEGORY_V4_ID_FURNITURE

        this.$snowplow.trackButtonClick(
          getInnerText(this.ctaBlock.innerBlocks[1].innerBlocks[0].innerBlocks[0].innerHtml),
          this.$config.v4Host + "/" + getV4UrlByCategoryId(categoryV4Id)
        )

        if (!categoryV4Id) {
          window.location.href = this.$config.v4Host + "/" + getV4UrlByCategoryId(categoryV4Id)
          this.submittingForm = false
          return
        }

        const payload = getV4ListingPayload(listing, categoryV4Id, this.$i18n.locale)

        if (payload.v === 2) {
          redirectToV2(payload, listing, this.$config.v4Host)
          this.submittingForm = false
          return
        }

        const listingService = new ListingService(this.$axios, this.$config)
        const response: ListingResponse = await listingService.postPreListing(
          payload,
          this.$i18n.locale
        )

        if (response?.data && response.data?.pre_listing_id && response.data?.category_id) {
          if (response.is_international) {
            redirectToV2(payload, listing, this.$config.v4Host)
            this.submittingForm = false
            return
          }

          addPreListingIdToSessionStorage(response.data)
          document.addEventListener("visibilitychange", () => {
            this.submittingForm = false
            this.submittedForm = false
            this.showFurnitureItems = false
          })
          window.location.href = getV4Url(
            this.$config.v4Host,
            response.data,
            payload,
            this.$i18n.locale
          )
          return
        } else {
          this.submittingForm = false
          if (this.getInventoryComputed) {
            this.$snowplow.trackCTAButtonFailed(
              "page",
              this.getItemsCount,
              "PreListing creation failed"
            )
          }
        }
      } catch (err) {
        this.submittingForm = false
        if (this.getInventoryComputed) {
          this.$snowplow.trackCTAButtonFailed(
            "page",
            this.getItemsCount,
            "PreListing creation error" + err
          )
        }
        console.log("PreListing creation error", err)
      }
    },

    hasValidation(block) {
      return patternCheck(block.attributes, VALIDATION)
    },

    onEmailFormOpen() {
      this.emailFormShow = true
    },

    onEmailFormClose() {
      this.emailFormShow = false
    },

    handleCallUsClick(label = "location_form_button"): void {
      this.$snowplow.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label,
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
    },

    hideItemsOnClickOutside(event): void {
      // If the user clicked at the Summary or Create/Edit Item dialog then we should not close the dropdown.
      if (event.target.closest(".extension-block") || event.target.closest("#modalContent")) return
      eventBus.$emit("close-dropdown")
      this.hideItems()
    },

    hideItems(): void {
      setTimeout(() => {
        this.showFurnitureItems = false
      }, 500)
    }
  }
})
