
/**
 * Short for `<input type="text" />` with additional features.
 * Set `place` event handler in the attributes and the component extend itself
 * with Europe restricted **Places** autocomplete
 * Its value should be a function which parameter receive the
 * [Google Places](https://developers.google.com/places/web-service/details#PlaceDetailsResponses)
 * object.
 */
export default {
  name: "PlacesSearch",

  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    /** Add a green check icon */
    check: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    labelPosition: {
      type: String,
      default: "top",
      validator: value => ["top", "left", "animated", "border"].includes(value)
    },
    labelBg: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    /** Hide error message - just red placeholder and border */
    noErrorMessage: {
      type: Boolean,
      default: false
    },
    /** Standard input types */
    type: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "xl",
      validator: value => ["xs", "s", "m", "l", "xl"].includes(value)
    },
    inputId: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkAndDisable: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off",
      required: false
    },
    /** Icon shortcut */
    iconLeft: {
      type: Array,
      default: () => []
    },
    /** Icon shortcut */
    iconRight: {
      type: Array,
      default: () => []
    },
    /** Icon size */
    // eslint-disable-next-line vue/require-default-prop
    iconSize: {
      type: Number,
      required: false
    },
    /** Icon colour */
    // eslint-disable-next-line vue/require-default-prop
    iconColor: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    showArrows: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    iconPadding: {
      type: Number,
      required: false,
      default: null
    },
    inputClasses: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    inputValue: "",
    placeDirty: false,
    guessPlaceTimeout: null,
    geocoder: null,
    placesAutocomplete: null,
    googleDisabled: false,
    googlePlaceholder: ""
  }),

  mounted() {
    if (!this.$config.mapsEnabled) {
      this.googleDisabled = true
      this.googlePlaceholder = "Use draft server instead"
    }
  },

  methods: {
    initMap() {
      if (!this.$el.querySelector("input")) return
      const options = {
        types: ["geocode", "establishment"],
        bounds: this.getAutoCompleteBoundaries()
      }
      this.placesAutocomplete = new google.maps.places.Autocomplete(
        this.$el.querySelector("input"),
        options
      )
      this.placesAutocomplete.setFields([
        "geometry",
        "formatted_address",
        "place_id",
        "address_components"
      ])
      this.placesAutocomplete.addListener("place_changed", () => {
        // Address was selected, no need to guess.
        this.clearGuessPlaceTimeout()
        this.placeDirty = false

        // const place = Object.freeze(autocomplete.getPlace())
        const place = this.placesAutocomplete.getPlace()
        this.inputValue = place.formatted_address

        if (!this.inputValue) {
          this.clearPlace()
        } else {
          this.$emit("place", place)
        }
      })

      this.geocoder = new google.maps.Geocoder()
    },

    getAutoCompleteBoundaries() {
      const boundaries = {
        es: {
          southwest: { lat: 34.761126, lng: -10.294783 },
          northeast: { lat: 44.285538, lng: 4.750203 }
        },
        en: {
          southwest: { lat: 49.686402, lng: -7.678393 },
          northeast: { lat: 59.241507, lng: 1.73144 }
        }
      }
      const locale = this.$i18n.locale
      const boundary = boundaries[locale] || boundaries.en
      return new google.maps.LatLngBounds(boundary.southwest, boundary.northeast)
    },

    updateSelf(value, event) {
      if (value === "") {
        this.clearPlace()
        return
      } else if (value.length >= 2 && !this.placesAutocomplete) {
        if (typeof google !== "undefined") {
          this.initMap()
        } else {
          const callback = () => {
            if (typeof google !== "undefined") {
              window.removeEventListener("maps-module:loaded", callback)
            }
            this.initMap()
          }
          window.addEventListener("maps-module:loaded", callback)
        }
      }

      if (event.data !== undefined) {
        // In this case the user fired an InputEvent, type = insertText, data = "X", composed = true
        // Where X can be a letter typed by the user and target.value is the full inserted value
        // Should consider that data = "" when the user removes a char from the input field
        this.placeDirty = true
      } else {
        // In this case the user fired a CustomEvent, type = undefined, data = undefined, composed = false
        // Where target.value is the full inserted value can be autocomplete or AutoFill
        // Of a Contact Address on Android / iPhone
        this.guessPlace(value)
      }
    },

    onFocus() {
      this.$emit("focus")
    },

    onBlur() {
      // If the user leaves the input without selecting an address,
      // but the dropdown is visible, try to use the first result.
      if (this.placeDirty) {
        let address = ""
        const item = document.querySelector(".pac-container .pac-item")

        if (item !== null) {
          address = Array.from(item.children, node => node.textContent)
            .filter(Boolean)
            .join(" ")
            .trim()

          // Autocomplete leave hidden .pac-container elements in dom,
          // remove it so it doesn't bother in the future.
          item.remove()
        }

        // Blur event triggering faster than `place_changed`,
        // wait a little bit, maybe an address was selected.
        this.guessPlaceTimeout = setTimeout(() => {
          this.guessPlace(address)
        }, 500) // Throttling Fast 3G would require timeout 600ms

        this.$emit("blur")
      }
    },

    guessPlace(address) {
      this.clearGuessPlaceTimeout()

      if (address !== "") {
        this.geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            const place = results[0]
            this.inputValue = place.formatted_address
            this.$emit("place", place)
            this.placeDirty = false
          }

          if (this.placeDirty) {
            this.clearPlace()
          }
        })
      } else {
        this.clearPlace()
      }
    },

    clearPlace() {
      this.inputValue = ""
      this.$emit("place", null)
      this.placeDirty = false
    },

    clearGuessPlaceTimeout() {
      if (this.guessPlaceTimeout !== null) {
        clearTimeout(this.guessPlaceTimeout)
        this.guessPlaceTimeout = null
      }
    }
  }
}
