import { ref, watch } from "vue"


export default {
  __name: 'Checkbox',
  props: {
  modelValue: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "input"],
  setup(__props, { emit }) {

const props = __props;





const innerChecked = ref(props.modelValue)

watch(
  () => props.modelValue,
  newVal => {
    innerChecked.value = newVal
  }
)

watch(innerChecked, newVal => {
  emit("update:modelValue", newVal)
})

const onChange = event => {
  emit("input", event.target.checked)
}

return { __sfc: true,props, emit, innerChecked, onChange }
}

}