import { render, staticRenderFns } from "./RemovalsLocationForm.vue?vue&type=template&id=d3436996&lang=pug"
import script from "./RemovalsLocationForm.vue?vue&type=script&lang=ts"
export * from "./RemovalsLocationForm.vue?vue&type=script&lang=ts"
import style0 from "./RemovalsLocationForm.vue?vue&type=style&index=0&id=d3436996&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AvailabilityRemark: require('/app/components/generic/AvailabilityRemark.vue').default,NewLocationPickupInput: require('/app/components/generic/NewLocationPickupInput.vue').default,PropertyPickupInput: require('/app/components/generic/PropertyPickupInput.vue').default,NewLocationDeliveryInput: require('/app/components/generic/NewLocationDeliveryInput.vue').default,PropertyDeliveryInput: require('/app/components/generic/PropertyDeliveryInput.vue').default,PhoneNumber: require('/app/components/generic/PhoneNumber.vue').default,Btn: require('/app/components/vantone/Btn.vue').default,RetrieveQuoteModal: require('/app/components/modals/RetrieveQuoteModal.vue').default})
