
import { getSizeClasses } from "~/scripts/css-helper"
import useUniqueId from "~/use/unique-id"

export default {
  name: "BaseTxt",

  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    /** Add a green check icon */
    check: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    labelPosition: {
      type: String,
      default: "top",
      validator: value => ["top", "left", "animated", "border"].includes(value)
    },
    labelBg: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    /** Hide error message - just red placeholder and border */
    noErrorMessage: {
      type: Boolean,
      default: false
    },
    /** Standard input types */
    type: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "xl",
      validator: value => ["xs", "s", "m", "l", "xl"].includes(value)
    },
    inputId: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkAndDisable: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off",
      required: false
    },
    /** Icon shortcut */
    iconLeft: {
      type: Array,
      default: () => []
    },
    /** Icon shortcut */
    iconRight: {
      type: Array,
      default: () => []
    },
    /** Icon size */
    // eslint-disable-next-line vue/require-default-prop
    iconSize: {
      type: Number,
      required: false
    },
    /** Icon colour */
    // eslint-disable-next-line vue/require-default-prop
    iconColor: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    showArrows: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    iconPadding: {
      type: Number,
      required: false,
      default: null
    },
    iconPaddingEnabled: {
      type: Boolean,
      default: false
    },
    inputClasses: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    activeFlag: false,
    txtId: "",
    editIsClicked: false,
    xPadding: null
  }),

  computed: {
    classes() {
      return getSizeClasses(this.size)
    },
    normalisedInputClasses() {
      return Array.isArray(this.inputClasses) ? this.inputClasses : []
    },

    placeholderText() {
      return this.labelPosition !== "animated" ? this.placeholder : ""
    },

    editIsVisible() {
      return this.checkAndDisable && !this.editIsClicked
    },

    iconFontSize() {
      let size = 32

      if (this.iconSize) {
        size = this.iconSize
      } else {
        switch (this.size) {
          case "xs":
            size = 16
            break
          case "s":
            size = 18
            break
          case "m":
            size = 20
            break
          case "l":
            size = 24
            break
          case "xl":
            size = 32
            break
        }
      }

      return size
    },

    iconStyles() {
      const styles = []

      if (this.iconPaddingEnabled) {
        if (this.iconLeft.length >= 2 || this.iconRight.length >= 2) {
          styles.push(`width: ${this.xPadding}px`)
        }
      }

      if (this.iconColor) {
        styles.push(`color: ${this.iconColor}`)
      }

      return styles
    },

    styles() {
      const styles = []

      if (this.iconPaddingEnabled) {
        if (this.iconLeft.length >= 2) {
          styles.push(`padding-left: ${this.xPadding}px`)
        }

        if (this.iconRight.length >= 2) {
          styles.push(`padding-right: ${this.xPadding}px`)
        }
      }

      if (!this.border) {
        styles.push(`border: 0`)
      }

      if (this.backgroundColor) {
        styles.push(`background-color: ${this.backgroundColor}`)
      }

      return styles
    }
  },

  mounted() {
    if (this.focused) {
      this.focus()
    }

    if (this.iconLeft.length >= 2 || this.iconRight.length >= 2) {
      if (this.iconPadding) {
        this.xPadding = this.iconPadding
      } else {
        this.xPadding = this.iconSize ? this.iconSize * 2 : this.iconFontSize * 2
      }
    }

    this.txtId = this.inputId ? this.inputId : "txt-" + useUniqueId()

    this.$emit("input-loaded")
  },

  methods: {
    focus() {
      this.$refs.input.focus()
    },

    onFocus() {
      this.activeFlag = true

      if (this.autocomplete === "off" && this.$refs.input) {
        this.$refs.input.setAttribute("autocomplete", "chrome-off")
      }

      this.$emit("focus")
    },

    onBlur() {
      this.activeFlag = false

      if (this.autocomplete === "off" && this.$refs.input) {
        this.$refs.input.setAttribute("autocomplete", "off")
      }

      this.$emit("blur")
    }
  }
}
