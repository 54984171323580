import Vue from "vue"
import axios from "axios"
import { formatNumberWithLocale } from "~/utils/formatNumberWithLocale"

interface ComponentData {
  trustpilotRating: number
  trustpilotCustomers: number
  trustpilotCustomersFormatted: string
  $style: Record<string, any>
}

export default Vue.extend({
  name: "TrustpilotBase",

  props: {
    lineBreaks: {
      type: Boolean,
      default: true
    },
    readReviewsLink: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: undefined
    },
    theme: {
      type: String,
      default: "dark",
      validator: (value: string) => ["dark", "light"].includes(value)
    }
  },

  data(): ComponentData {
    return {
      trustpilotRating: 0,
      trustpilotCustomers: 0,
      trustpilotCustomersFormatted: "0",
      $style: {}
    }
  },

  computed: {
    css(): string[] {
      const css: string[] = []

      if (this.width) {
        css.push(`width: ${this.width}px`)
      }

      return css
    },

    classes(): string[] {
      const classes: string[] = []
      classes.push(this.$style[this.theme])

      return classes
    }
  },

  async created() {
    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey
    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotRating = response.data.score.trustScore
        this.trustpilotCustomers = response.data.numberOfReviews.total
        this.trustpilotCustomersFormatted = formatNumberWithLocale(
          this.trustpilotCustomers,
          this.$i18n.locale
        )
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }
  }
})
