
import Vue from "vue"
import { mapGetters } from "vuex"
import { LiveChatWidget } from "@livechat/widget-vue/v2"
import { getAttributes } from "~/utils/block"
import { GET_INVENTORY } from "~/store/pages/getters"
import { SET_INVENTORY, SET_WINDOW_WIDTH } from "~/store/pages/actions"
import { GET_CREDIT_CODE_VALUE } from "~/store/credit-code/getters"
import { URL_PARAM_HIDE_ADMIN } from "~/constants/booking-insights"

interface ComponentData {
  blocks: Block[]
  hideAdmin: boolean
  isKeyboardVisible: boolean
  isAndroid: boolean
}

export default Vue.extend({
  name: "PageLayoutRebrand",

  components: {
    LiveChatWidget
  },

  props: {
    page: {
      type: Object as () => Page,
      required: true
    }
  },

  data(): ComponentData {
    return {
      blocks: [],
      hideAdmin: false,
      isKeyboardVisible: false,
      isAndroid: false
    }
  },

  computed: {
    ...mapGetters("pages", {
      getInventory: GET_INVENTORY
    }),
    ...mapGetters("credit-code", {
      creditCodeValue: GET_CREDIT_CODE_VALUE
    }),

    shouldDisplayLiveChat(): boolean {
      return Boolean(
        this.$config.liveChatLicense && this.getInventory === false && this.$i18n.locale !== "es"
      )
    }
  },

  created() {
    this.blocks = [...this.page?.blocks]
    this.hideAdmin = this.$route.query[URL_PARAM_HIDE_ADMIN] === "true"
  },

  beforeMount() {
    this.$store.dispatch(`pages/${SET_WINDOW_WIDTH}`, window.innerWidth)
  },

  mounted() {
    if (this.getInventory === undefined) {
      this.$store.dispatch(`pages/${SET_INVENTORY}`, false)
    }

    const ua = navigator.userAgent.toLowerCase()
    if (ua.includes("android")) {
      this.isAndroid = true
      if (window.visualViewport) {
        window.visualViewport.addEventListener("resize", this.updateKeyboardVisibility)
      }
    }
  },

  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    if (this.isAndroid) {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", this.updateKeyboardVisibility)
      }
    }
  },

  methods: {
    getAttributes,

    updateKeyboardVisibility() {
      if (window.visualViewport) {
        // Check if the keyboard is visible
        this.isKeyboardVisible = window.visualViewport.height < screen.height * 0.6 // Adjust threshold if needed

        if (this.isKeyboardVisible && this.isAndroid) {
          document.body.classList.add("hide-RIQ")
        } else {
          document.body.classList.remove("hide-RIQ")
        }
      }
    }
  }
})
