
import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import axios from "axios"
import { mapGetters, mapActions } from "vuex"
import { patternCheck } from "~/utils/block"
import { formatNumberWithLocale } from "~/utils/formatNumberWithLocale"
import { GET_LISTING } from "~/store/listing/getters"
import {
  ListingService,
  getV4Url,
  getV4ListingPayload,
  addPreListingIdToSessionStorage,
  isValidPropertyType
} from "~/services/public/Listing"
import { AVAILABILITY_REMARK, CALL_US, RETRIEVE_QUOTE, VALIDATION } from "~/constants/blocks"
import { redirectToV2 } from "~/utils/v4-redirect"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import eventBus from "~/scripts/bus"
import { SET_DELIVERY_PROPERTY_TYPE, SET_PICKUP_PROPERTY_TYPE } from "~/store/listing/actions"
import { extractHref, getInnerText, getv4LocaleFromI18nLocale } from "~/scripts/useful-functions"
import { GET_WINDOW_WIDTH } from "~/store/pages/getters"
import {
  ADD_FLOORS,
  ADD_PROPERTY_TYPES,
  ADD_PROPERTY_TYPES_FLAT,
  ADD_PROPERTY_TYPES_HOUSE
} from "~/store/property/actions"
import { CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"

interface ComponentData {
  submittedForm: boolean
  submittingForm: boolean
  emailFormShow: boolean
  trustpilotCustomers: string
}

export default Vue.extend({
  name: "RemovalsLocationForm",

  components: {
    ValidationObserver
  },

  props: {
    initialPropertyType: {
      type: String,
      default: ""
    },
    propertyTypeHidden: {
      type: Boolean
    },
    propertyFloorHidden: {
      type: Boolean
    },
    block: {
      type: Object as () => Block,
      required: true
    }
  },

  data(): ComponentData {
    return {
      submittedForm: false,
      submittingForm: false,
      emailFormShow: false,
      trustpilotCustomers: "0"
    }
  },

  computed: {
    ...mapGetters("pages", {
      getWindowWidth: GET_WINDOW_WIDTH
    }),
    ...mapGetters("listing", {
      getListing: GET_LISTING
    }),
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    }),

    locationBlock(): Record<string, any> {
      return this.block.innerBlocks[0]
    },

    propertyBlock(): Record<string, any> {
      return this.block.innerBlocks[this.block.innerBlocks.length - 2]
    },

    ctaBlock(): Record<string, any> {
      return this.block.innerBlocks[this.block.innerBlocks.length - 1]
    },

    hasAvailabilityRemark(): boolean {
      return this.patternCheck(this.block.attributes, AVAILABILITY_REMARK)
    },

    hasCallUs(): boolean {
      return this.patternCheck(this.block.attributes, CALL_US)
    },

    hasRetrieveQuote(): boolean {
      return this.patternCheck(this.block.attributes, RETRIEVE_QUOTE)
    },

    formattedTrustpilotCustomers(): string {
      return formatNumberWithLocale(this.trustpilotCustomers, this.$i18n.locale)
    }
  },

  watch: {
    submittingForm(val: boolean): void {
      if (val) {
        eventBus.$emit("submitting-location-form")
        return
      }
      eventBus.$emit("submitted-location-form")
    }
  },

  async created() {
    if (this.initialPropertyType) {
      this.setPickupPropertyType(this.initialPropertyType)
      this.setDeliveryPropertyType(this.initialPropertyType)
    }

    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey
    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotCustomers = response.data.numberOfReviews.total
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }

    const itemsUrl = this.$config.v4Host + "/api/v4/booking-flow/config/house-move"
    try {
      const axiosInstance = axios.create() as any
      const headers = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "AV-locale": getv4LocaleFromI18nLocale(this.$i18n.locale)
        }
      }
      const response = await axiosInstance.get(itemsUrl, headers)
      if (response.status === 200 && response.data) {
        this.addPropertyTypes(response.data.propertyTypes)
        this.addFloors(response.data.propertyFloors.floors)
        const houseObject = response.data.propertyTypes.find(item => item.identifier === "house")
        this.addPropertyTypesHouse(houseObject.values)
        const flatObject = response.data.propertyTypes.find(item => item.identifier === "flat")
        this.addPropertyTypesFlat(flatObject.values)
      }
    } catch (err) {
      console.log("Property config error", err)
    }
  },

  mounted() {
    if (this.initialPropertyType) {
      this.setPickupPropertyType(this.initialPropertyType)
      this.setDeliveryPropertyType(this.initialPropertyType)
    }
  },

  beforeMount() {
    eventBus.$on("submit-location-form", this.submitForm)
  },

  beforeDestroy() {
    eventBus.$off("submit-location-form")
  },

  methods: {
    ...mapActions("listing", {
      setPickupPropertyType: SET_PICKUP_PROPERTY_TYPE,
      setDeliveryPropertyType: SET_DELIVERY_PROPERTY_TYPE
    }),
    ...mapActions("property", {
      addFloors: ADD_FLOORS,
      addPropertyTypes: ADD_PROPERTY_TYPES,
      addPropertyTypesHouse: ADD_PROPERTY_TYPES_HOUSE,
      addPropertyTypesFlat: ADD_PROPERTY_TYPES_FLAT
    }),

    extractHref,
    getInnerText,
    patternCheck,

    scrollInputToTop(el: string): void {
      if (this.getWindowWidth && this.getWindowWidth > 768) return

      let element
      switch (el) {
        case "pickup":
          element = this.$refs.pickupInput
          break
        case "delivery":
          element = this.$refs.deliveryInput
          break
        default:
          return
      }
      if (element && element.$el.offsetTop) {
        window.scrollTo({
          top: element.$el.offsetTop - 25,
          behavior: "smooth"
        })
      }
    },

    async submitForm(): Promise<void> {
      if (this.submittingForm) return
      this.submittedForm = true
      this.submittingForm = true
      try {
        const listing = this.getListing
        if (!isValidPropertyType(listing?.pickup.propertyType)) {
          await this.setPickupPropertyType(null)
        }
        if (!isValidPropertyType(listing?.delivery.propertyType)) {
          await this.setDeliveryPropertyType(null)
        }
        const isValid = await (
          this.$refs.observer as InstanceType<typeof ValidationObserver>
        ).validate()
        if (!isValid) {
          this.submittingForm = false
          eventBus.$emit("close-dropdown")
          return
        }

        const categoryV4Id = CATEGORY_V4_ID_HOUSE_MOVE

        this.$snowplow.trackButtonClick(
          getInnerText(this.ctaBlock.innerBlocks[1].innerBlocks[0].innerBlocks[0].innerHtml),
          this.$config.v4Host + "/" + CATEGORY_V4_ID_HOUSE_MOVE
        )

        const payload = getV4ListingPayload(listing, categoryV4Id, this.$i18n.locale)

        // This condition redirects to auction if the locations are international
        // or one of the locations is on an island.
        if (payload.v === 2) {
          redirectToV2(payload, listing, this.$config.v4Host)
          this.submittingForm = false
          return
        }

        const listingService = new ListingService(this.$axios, this.$config)
        const response: ListingResponse = await listingService.postPreListing(
          payload,
          this.$i18n.locale
        )

        if (response?.data && response.data?.pre_listing_id && response.data?.category_id) {
          if (response.is_international) {
            redirectToV2(payload, listing, this.$config.v4Host)
            this.submittingForm = false
            return
          }

          addPreListingIdToSessionStorage(response.data)
          document.addEventListener("visibilitychange", () => {
            this.submittingForm = false
            this.submittedForm = false
          })

          window.location.href = getV4Url(
            this.$config.v4Host,
            response.data,
            payload,
            this.$i18n.locale
          )
          return
        } else {
          this.submittingForm = false
        }
      } catch (err) {
        this.submittingForm = false
        console.log("PreListing creation error", err)
      }
    },

    hasValidation(block: Record<string, any>): boolean {
      return this.patternCheck(block.attributes, VALIDATION)
    },

    onEmailFormOpen(): void {
      this.emailFormShow = true
    },

    onEmailFormClose(): void {
      this.emailFormShow = false
    },

    handleCallUsClick(label: string = "location_form_button"): void {
      this.$snowplow.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label,
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
    }
  }
})
